@import "./assets/plugins/custom/tagsinput/tagsinput.css";
@import "./assets/plugins/custom/datatables/datatables.bundle.css";
/*@import "./assets/css/slick-carousel/slick.min.css";*/
/*@import "./assets/css/slick-carousel/slick-theme.min.css";*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
@import "~bootstrap/dist/css/bootstrap.css";
/*@import "~socicon/css/socicon.css";*/
/*@import "~@fortawesome/fontawesome-free/css/all.min.css";*/
@import "./assets/plugins/global/fonts/flaticon/flaticon.css";
@import "./assets/plugins/global/fonts/keenthemes-icons/ki.css";
@import "./assets/css/themes/layout/header/base/light.css";
@import "./assets/css/themes/layout/header/menu/light.css";
@import "./assets/css/themes/layout/brand/dark.css";
@import "./assets/css/themes/layout/aside/dark.css";
@import "./assets/plugins/global/plugins.bundle.css";
@import "./assets/css/style.bundle.css";
@import "./assets/css/jstree/jstree.bundle.css";
.brand-dark {
@import "./assets/css/themes/layout/brand/dark.css";
}
.brand-light {
@import "./assets/css/themes/layout/brand/light.css";
}
.dropdown-toggle::after {
  /*display: none !important;*/
}
.cursor-pointer {
  cursor: pointer;
}
i.plus_icon {
  font-size: 8px !important;
  position: relative;
  top: -1px;
  right: -4px;
}
.form-group {
  margin-bottom: 1rem;
}
.iconCenter .dropdown-toggle.btn:after{
  margin-left: -0.2rem!important;
}
body .form-control.is-invalid {
  background: none !important;
  padding-right: inherit !important;
}
.table_rotating {
  width: 20px;
  height: 20px;
  margin-left: 10px !important;
}
.table_loader {
  position: absolute;
  top: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
  left: 50%;
  background: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 4px #0000000f;
}
.pagination_main .dropdown-menu{
  min-width: 100% !important;
}
.pagination_main .dropdown-menu span{
  font-weight: 600;
}
/*.pagination_main #pagination_btn span.count_count_icon::after {*/
/*  border: 0;*/
/*  margin-right: -2px;*/
/*  font-size: 0.6rem;*/
/*  display: inline-block;*/
/*  font-family: Ki;*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-variant: normal;*/
/*  line-height: 1;*/
/*  text-decoration: inherit;*/
/*  text-rendering: optimizeLegibility;*/
/*  text-transform: none;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  font-smoothing: antialiased;*/
/*  content: "";*/
/*  position: relative;*/
/*  top: -1px;*/
/*}*/
a#pagination_btn{
  min-width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.45rem 1rem;
}
a#pagination_btn2{
  min-width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.45rem 1rem;
}
span.count_count_icon {
  font-size: 1rem;
  font-weight: 500;
}


.toast.toast-success {
  background-color: #3699FF !important;
  color: #fff !important;
}
.toast.toast-error {
  background-color: red !important;
  color: #fff !important;
}


.page_count{
  font-weight: 500;
}
.pagination_main ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.pagination_main{
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}
.pagination_main ul li {
  margin-left: 0.5rem;
}
.pagination_main ul a:hover {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  background-color: #3699FF;
  color: #ffffff !important;
}
.pagination_main ul li a{
  display: flex;
  align-items: center;
  height: 2.25rem;
  min-width: 2.25rem;
  justify-content: center;
  border-radius: 0.42rem;
  border: 0;
  color: #7E8299;
  padding: 0.5rem;
}
.pagination_main ul.pagination > li a[aria-label="First"],
.pagination_main ul.pagination > li a[aria-label="Previous"],
.pagination_main ul.pagination > li a[aria-label="Next"],
.pagination_main ul.pagination > li a[aria-label="Last"] {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  background: #F3F6F9 !important;
  color: #7E8299 !important;
  font-size: 1rem;
  padding: 0;
}
.pagination_main ul.pagination > li a[aria-label="First"] span,
.pagination_main ul.pagination > li a[aria-label="Previous"] span,
.pagination_main ul.pagination > li a[aria-label="Next"] span,
.pagination_main ul.pagination > li a[aria-label="Last"] span {
  font-size: 18px;
}
.pagination_main ul.pagination > li a[aria-label="First"]:hover,
.pagination_main ul.pagination > li a[aria-label="Previous"]:hover,
.pagination_main ul.pagination > li a[aria-label="Next"]:hover,
.pagination_main ul.pagination > li a[aria-label="Last"]:hover {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  background: #3699FF !important;
  color: #ffffff !important;
}
.type_main .card-body {
  width: 100%;
  height: 300px;
  overflow: auto;
}
.selector_main .item {
  font-size: 13px;
  margin-bottom: 5px;
  cursor: pointer;
  line-height: 22px;
  padding: 3px;
}
.selector_main .item.active {
  background: #3699ff;
  color: #fff;
}
.type_main .card-body::-webkit-scrollbar{
  width: 5px;
}
.type_main .card-body::-webkit-scrollbar-thumb{
  background: #E4E6EF;
}
.type_main .card-body::-webkit-scrollbar-track{
  background: transparent;
}
/*#addTypeModal .modal-dialog {*/
/*  max-width: 400px;*/
/*}*/
.modal-xxl {
  max-width: 93vw !important;
  width: 93vw !important;
}
.type_main .card-body > div {
  cursor: pointer;
}
.main-content .card.card-custom.card-stretch.gutter-b {
  height: calc(100% - 30px);
}


/*// Calendar CSS*/
 .fc-unthemed .fc-toolbar .fc-button {
   color: #B5B5C3;
   background: transparent;
   border: 1px solid #EBEDF3;
   text-shadow: none !important;
   -webkit-box-shadow: none !important;
   box-shadow: none !important;
 }
.fc-unthemed .fc-toolbar .fc-button {
  outline: none !important;
  height: 2.75rem;
  padding: 0 1.25rem;
  font-size: 1rem;
}
.fc-unthemed .fc-toolbar .fc-button:focus, .fc-unthemed .fc-toolbar .fc-button:active, .fc-unthemed .fc-toolbar .fc-button.fc-button-active {
  background: #3699FF;
  color: #FFFFFF;
  border: 1px solid #3699FF;
}
.fc-unthemed .fc-toolbar .fc-button:hover {
  background: #F3F6F9;
  border: 1px solid #EBEDF3;
  color: #B5B5C3;
}
.fc-unthemed .fc-toolbar .fc-button:focus, .fc-unthemed .fc-toolbar .fc-button:active, .fc-unthemed .fc-toolbar .fc-button.fc-button-active {
  background: #3699FF;
  color: #FFFFFF;
  border: 1px solid #3699FF;
}
.fc .fc-button-primary:not(:disabled):active {
  background: #3699FF !important;
  color: #FFFFFF !important;
  border: 1px solid #EBEDF3 !important;
}
.fc .fc-button-primary:disabled {
  background: white !important;
  color: #B5B5C3 !important;
  border: 1px solid #EBEDF3 !important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background: #3699FF !important;
  color: #FFFFFF !important;
  border: 1px solid #EBEDF3 !important;
}
.fc .fc-col-header-cell-cushion {
  color: #B5B5C3 !important;
  font-weight: 100 !important;
}
.fc-h-event .fc-event-main-frame {
  color: #ffffff !important;
  padding: 4px !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
/*//background-color: green !important;*/
/*//border-radius: 3px !important;*/
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 4px !important;
/*//background-color: orange !important;*/
  color: #0c0f1a !important;
}


.bootstrap-multi-select button {
  height: 100%!important;
}
.bootstrap-select > .dropdown-toggle {
  /*padding: 0.45rem 1rem !important;*/
  padding: 0.65rem 1rem !important;
}
.fc .fc-daygrid-event-harness {
  cursor: pointer !important;
}
.bootstrap-select button {
  height: calc(1.35em + 1.1rem + 2px) !important;
}

.card-custom .card-body .row {
  align-items: flex-end;
}
.form-group {
  margin-bottom: 1rem;
}
/*Loader Background opacity color */
.ngx-overlay.loading-foreground {
  background-color: rgba(40, 40, 40, 0.2) !important;
  /*background-color: #28282887 !important;*/
}
.product_name .input-group {
  border: 1px solid #E4E6EF !important;
  border-radius: 0.28rem !important;
}
.quick-search .quick-search-result a {
  display: block;
  padding: 5px 3px;
  cursor: pointer;
}
.product_name .quick-search-wrapper {
  position: absolute;
  background: #fff;
  z-index: 9999;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin: 0 !important;
  padding: 5px 3px;
}
.product_name form.quick-search-form {
  border-bottom: 0px solid #EBEDF3 !important;
  padding-bottom: 0 !important;
}
.diagnosis-search-bar.pointOfSale {
  padding: 8px 5px;
  box-shadow: 0px 0px 4px 1px #ddd;
  margin: 4px 0;
  position: absolute;
  z-index: 999999;
  background: #fff;
  width: 100%;
}
.diagnosis-search-bar.pointOfSale div p {
  border-bottom: 1px solid #ddd;
  padding: 0 0 5px 0px;
}
input::-webkit-inner-spin-button {
  display: none;
}
#addTypeModal .modal-dialog,
#catModal .modal-dialog,
#subcatModal .modal-dialog {
  margin-top: 70px;
}

/*login card*/
.login-card {
  width: 300px;
}



.urgent{
  background-color: #bf8484!important;
  color: #ffffff!important;
}
.urgent:hover{
  background-color: #d2a0a099!important;
}
.returned{
  background-color: #c1ba8680!important;
  color: #ffffff!important;
}
.returned:hover{
  background-color: #ece6b4!important;
}
.cancel{
  background-color: #c70000!important;
  color: #ffffff!important;
}
.cancel:hover{
  background-color: #c70000!important;
}
.opd-btn{
  background-color: #f3d5e280!important;
  color: #ffffff!important;
}
.opd-btn:hover{
  background-color: #f3d5e2!important;
}
.ipd-btn{
  background-color: #d6903880!important;
  color: #ffffff!important;
}
.ipd-btn:hover{
  background-color: #d69038!important;
}
.emr-btn{
  background-color: #EEE5FF!important;
}
.emr-btn:hover{
  background-color: #8950FC!important;
}


.bootstrap-live-select .dropdown-menu {
  width: 300px;
  height: 400px;
}

/*searching boxes*/
.search-result-main{
  z-index: 100;
  position: absolute;
  max-height: 130px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  overflow-y: scroll;
  padding: 0px 5px;
  min-width: 100%;
  max-width: 100%;
}
.search_box {
  position: relative;
}
.search-result-main p{
  margin-bottom: 3px;
  margin-top: 3px;
  padding: 4px;
  cursor: pointer;
  font-size: 12px;
}
.search-result-main p:hover{
  background-color: lightblue;
  color: #ffffff;
}
.diagnosis-search-bar{
  width: 100%;
  z-index: 100;
  position: absolute;
  max-height: 130px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  overflow-y: scroll;
  padding: 0px 5px;
}
.diagnosis-search-bar p{
  margin-bottom: 3px;
  margin-top: 3px;
  padding: 4px;
  cursor: pointer;
  font-size: 12px;
}
.diagnosis-search-bar p:hover{
  background-color: lightblue;
  color: #ffffff;
}

div.search-result-main.opd-medicine-search {
  width: inherit !important;
}
div.diagnosis-search-bar.opd-diagnosis-search {
  width: inherit !important;
}

.remove-table-lines tr th {
  border-bottom: 0px !important;
  border-top: 0px !important;
}
.remove-table-lines tr td {
  border-bottom: 0px !important;
  border-top: 0px !important;
}

.max-height-250 {
  max-height: 250px;
}
.max-height-200 {
  max-height: 200px;
}

/*tagsinput css*/
.ipd-allergy-field .bootstrap-tagsinput,
.tagsInput .bootstrap-tagsinput {
  width: 100%;
  border: 1px solid #E4E6EF !important;
  box-shadow: none !important;
}
.tagsHeight .bootstrap-tagsinput {
  max-height: 60px !important;
  overflow-y: auto;
}
.ipd-allergy-field .bootstrap-tagsinput .label.label-info,
.tagsInput .bootstrap-tagsinput .label.label-info {
  width: auto;
  /*background-color: #25a1c5;*/
  background: #F3F6F9;
  /*padding: 2px 4px;*/
  float: left/*rtl:right*/;
  color: #000000ad;

  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  margin: 0.1rem 0.4rem 0.1rem 0;
}
.ipd-allergy-field .bootstrap-tagsinput .label ,
.tagsInput .bootstrap-tagsinput .label {
  border-radius: .25em;
}
.medicine-search .search-result-main {
  width: auto !important;
}
.custom-card-body {
  padding: 1rem !important;
}

/*button active remove*/
.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-primary:focus:not(.btn-text) {
   color: #FFFFFF;
  background-color: #3699FF;
   border-color: #3699FF
}

div#basicChnagesModal.show {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
div#leaveApprovelModal.show > div.modal-dialog {
  /*max-width: 1000px !important;*/
}
div#leaveApprovelModal.show {
  /*display: flex !important;*/
  /*justify-content: center;*/
  /*align-items: center;*/
}
.imple_row span:last-child > span.next-operator {
  display: none;
}
.terms_listing {
  min-height: 250px;
  max-height: 250px;
  overflow-y: auto;
}
input.new_consent.required {
  border: 1px solid red;
}
.serviceName button, .pickers button {
  height: calc(1.35em + 1.1rem + 2px);
}
.tagsInput .select2 {
  width: 100% !important;
}
/*table td {*/
/*  word-break: break-all !important;*/
/*}*/
.pagination_main .dropdown-menu a.dropdown-item {
  cursor: pointer;
}
#medicationsAllergies select2-container {
  width: 100% !important;
}
.moDoctors .bootstrap-select .dropdown-menu {
  max-height: 300px;
  height: auto;
}
.form-group {
  margin-bottom: 1.3rem!important;
}
label {
  margin: 0;
}
.boot-dropdown .dropdown.bootstrap-select .dropdown-menu.show,
.serviceReport .dropdown.bootstrap-select .dropdown-menu.show {
  max-width: 100% !important;
}
.selectOptionBottom .bootstrap-select .dropdown-menu{
  transform: translate3d(0px, 33px, 0px)!important;
  /*transform: translate3d(88px, 469px, 0px) !important;*/
}
.selectOptionBottom .bootstrap-select .dropdown-menu .inner.show{
  /*margin-top: -5px !important;*/
  min-height: 200px !important;
}
.selectOptionBottom .bootstrap-select .dropdown-menu .inner.show ul li:first-child {
  margin-top: -30px;
}
.selectOptionLeft .dropdown-menu{
  left: auto!important;
  right: 0px!important;
  transform: translate3d(0px, 38px, 0px)!important;
  /*max-height: 200px !important;*/
}
.table.table-head-custom thead tr, .table.table-head-custom thead th {
  text-transform: capitalize;
}
.pointer-events-no{
  pointer-events: none!important;
}
.bootstrap-tags input{
  width: 100% !important;
}
.is-invalid button.btn.dropdown-toggle {
  border: 1px solid #F64E60 !important;
}
td.payroll_name:hover {
  cursor: pointer;
  color: #3699FF !important;
}
td.payroll_name {
  font-weight: 600;
}
button.toggle-btn-rotate[aria-expanded="true"] i {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out !important;
}
button.toggle-btn-rotate[aria-expanded="false"] i {
  transition: all 0.3s ease-in-out !important;
}
.table-hover tbody tr:hover {
  color: #3F4254;
  background-color: #e4e6ef47;
}
.wizard-label:focus {
  outline: 0 !important;
}
.wizard-label:hover {
  cursor: pointer !important;
}
/*.actionDropdown .dropdown-menu.show {*/
/*  transform: translate3d(-95px, 33px, 0px) !important;*/
/*}*/
.profile_card a {
  flex-basis: 33.333%;
}
.select2-selection__choice{
  font-weight: 100!important;
}
.roasterSelection + button.btn.dropdown-toggle.btn-light.bs-placeholder .filter-option-inner-inner {
  visibility: hidden;
}
.roasterSelection + button.btn.dropdown-toggle {
  width: 150px;
  max-width: 150px;
}
.roster-bar > div:last-child {
  flex: 0 0 8%;
  max-width: 8%;
}
.roster-bar > div {
  flex: 0 0 23%;
  max-width: 23%;
}
.roasterSelection.popup + button.btn.dropdown-toggle {
  width: 100%;
  max-width: 100%;
}
.roasterSelection + button.btn.dropdown-toggle .filter-option-inner-inner {
  white-space: break-spaces;
}
.popover-body {
 padding: 0.6rem 1rem;
 border-radius: 3px;
}

.truncate-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.label.label-lg.label-inline{
  width: max-content!important;
  max-width: 190px;
  height: auto;
}

@media (max-width: 480px) {
  .flex-small-column {
    flex-direction: column;
  }
}
@media (max-width: 766px) {
  .flex-medium-column {
    flex-direction: column;
  }
}
td{
  word-break: break-word
}
td span{
  word-break: break-word
}

.sortable-container {
  border: 0 !important;
}
.sortable-container .sortable-list .active {
  background-color: #80b9f563 !important;
}
.tox .tox-tbtn--bespoke .tox-tbtn__select-label{
  width: auto!important;
}
.tox-statusbar__text-container {
  display: none !important;
}
#modalView div#kt_subheader {
  display: none;
}

.bootstrap-select-container .dropdown-menu{
  width: 100%!important;
}
.border-alert{
  /*border-color: red !important;*/
  border: 1px solid red;
  border-radius: 4px;
}
.timeline-Header{
  display: none!important;
}
.is-invalid-range{
  background-color: #F64E60!important;
  color: #ffffff;
}
.is-invalid-text{
  color: #F64E60!important;
}
#rosterCalendar .datepicker-days>table>thead>tr>th.prev,
#rosterCalendar .datepicker-days>table>thead>tr>th.datepicker-switch,
#rosterCalendar .datepicker-days>table>thead>tr>th.next {
  display: none !important;
}
#rosterCalendar .datepicker.datepicker-inline, .table-condensed {
  width: 100% !important;
}
/* Hide days of previous month */
#rosterCalendar td.old.day{
  background: #D2D2D2;
  pointer-events: none;
}
#rosterCalendar table {
  border-collapse: separate !important;
}
/* Hide days of next month */
#rosterCalendar td.new.day{
  background: #D2D2D2;
  pointer-events: none;
}
#rosterCalendar {
  flex-grow: 1 !important;
}


/*slots reservation CSS: Start*/
#reservationCalendar .datepicker-days>table>thead>tr>th.prev,
#reservationCalendar .datepicker-days>table>thead>tr>th.datepicker-switch,
#reservationCalendar .datepicker-days>table>thead>tr>th.next {
  display: none !important;
}
#reservationCalendar .datepicker.datepicker-inline, .table-condensed {
  width: 100% !important;
}
/* Hide days of previous month */
#reservationCalendar td.old.day{
  background: #D2D2D2;
  pointer-events: none;
}
#reservationCalendar table {
  border-collapse: separate !important;
}
/* Hide days of next month */
#reservationCalendar td.new.day{
  background: #D2D2D2;
  pointer-events: none;
}
#reservationCalendar {
  flex-grow: 1 !important;
}
.pac-container {
  z-index: 9999 !important;
}
/*slots reservation CSS: End*/


/*slick slider CSS: Start*/

.slick-dots li button:before
{
  font-size: 25px;
  line-height: 25px;
}
.slick-prev:before {
  content: "";

  font-size: 20px;
}

.slick-next:before {
  content: "";

  font-size: 20px;
}
.btn-arrow{
  border: 2px solid #484848;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  z-index: 2;
}
.btn-icon{
  font-size: 20px;
  color:#484848;
}
/*Slick Slider CSS: End*/
.prescriptionReportPDF strong{
  font-size: 16px!important;
}
.prescriptionReportPDF{
  font-size: 16px!important;
}
  /* For Defaulat pdf report patient name */
  .pdf-report-Patient-name-area {
    font-size: 22px!important;
    font-weight: bolder;
  }
  /* For Defaulat pdf report patient heading  */
  .pdf-report-Patient-heading {
    font-size: 16px;
    font-weight: 600;

  }
  /* For Defaulat pdf report patient heading content*/
  .pdf-report-patient-heading-content {
    font-size: 16px;
    font-weight: 400;
  }
  .patient-info-header-main td{
    vertical-align: top;
  }
.cal-week-view .cal-event{
  color: #0a0a0a!important;
  white-space: break-spaces;
}
.cal-week-view .cal-time{
  width: 45px!important;
}
@media print {
  .prescriptionReportPDF{
    font-size: 16px!important;
  }
  .prescriptionReportPDF strong{
    font-size: 16px!important;
  }
  @page {
    size: auto!important;
  }
}

.isBgPlaceHolder{
  position: relative;
  height: 100%;
}
.isBgPlaceHolder:before{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  color: #0d745e;
  font-size: 120px;
  font-weight: 500px;
  display: grid;
  justify-content: center;
  align-content: center;
  opacity: 0.2;
  transform: rotate(-50deg);
}
.isDraftPlaceHolder:before{
  content: 'DRAFT BILL';
}
.isDuplicatePlaceHolder:before{
  content: 'Duplicate BILL';
}
.isDuplicateGRN:before{
  content: 'DUPLICATE';
}
.card{
    border: none!important;
  }
#screenSearchMain .btn{
  visibility: hidden!important;
}
.custom-error {
  font-size: 11px;
  opacity: 0.7;
  margin-top: 4px;
  color: #e82424;
}
.intl-tel-input{
  position: relative!important;
  display: block!important;
}
.countryLib .filter-option-inner-inner{
  padding-left: 40px!important;
}
.intl-tel-input .flag-container{
  left: 0!important;
}
.intl-tel-input .country-list{
  width: 312px!important;
}
.countriesFlags .intl-tel-input.separate-dial-code .selected-dial-code{
  padding-right: 150px!important;
  line-height: 32px!important;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  flex-direction: row-reverse !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove{
  margin-left: 5px !important;
  margin-right: 2px !important;
}
.hideTopBarSideBar .setBodyLayout{
  padding-left: 0;
  padding-top: 56px;
}
.hideTopBarSideBar .hideShowTopBarSideBar{
  display: none!important;
}
.hideTopBarSideBar .subheader-solid{
  top: 1px!important;
  left: 0!important;
}
.hideTopBarAndSideBar .setBodyLayout{
  padding-left: 0px!important;
  padding-top: 0px!important;
}
.hideTopBarAndSideBar .hideShowTopBarSideBar{
  display: none!important;
}
.hideTopBarAndSideBar .subheader-solid{
  top: 1px!important;
  left: 0!important;
}
.note-editor {
  border: 1px solid #E4E6EF!important;
}
.note-editor .note-toolbar {
  padding: 0.1rem 0.1rem!important;
}
.label-cliped{
  clip-path: polygon(25% 0%, 60% 1%, 100% 50%, 60% 100%, 0 100%, 0 0);
}
@media print {
      body {
        -webkit-print-color-adjust: exact; /* For Chrome and Safari */
        color-adjust: exact; /* For Firefox */
      }
    }
/*@page {*/
/*   margin: auto!important;*/
/*  }*/

/* Disable autocomplete suggestions for all input elements */
input {
  -webkit-text-fill-color: inherit !important;
}
.checkbox {
    display: inline-flex;
}

.datepicker-icon {
  position: relative;
}

.datepicker-icon i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 11px;
  color: black;
}
.mrn-picker-width .dropdown-menu {
  width: 200px!important;
}
.dropdown-picker-max-width .dropdown-menu {
  max-width: 300px!important;
}
.text-green{
  color: green!important;
}
